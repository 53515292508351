import Countdown from 'react-countdown'

import './AboutPage.scss'
import banner from '../../assets/images/usercp-bottom-banner.png'
import mithrilBanner from '../../assets/images/mithril.jpg'
import archridiumBanner from '../../assets/images/archridium.jpg'
import palladiumBanner from '../../assets/images/palladium.jpg'
import demoniteBanner from '../../assets/images/demonite.jpg'
import dragoniumBanner from '../../assets/images/dragonium.jpg'
import bannerAbout from '../../assets/images/183.png'
import devil from '../../assets/images/d1692a34d43a.png'
import verticalBanner from '../../assets/images/vert-banner.png'
import bg9 from '../../assets/video/bg9.mp4'
import logo from '../../assets/images/logo2.png'
import React from 'react'
import { Link } from 'react-router-dom'
/**
 * AboutPage uses contentRenderer to render the content of the page
 * Each contentRenderer takes in a title and text or an image
 * and are rendered in the order they are called alternating between left and right side
 */
const AboutPage = () => {
  // Renderer callback with condition
  const renderer = ({ days, completed }: { days: number; completed: boolean }) => {
    if (completed) {
      return <React.Fragment />
    } else {
      return <span className='about__countdown'>{days} Days Until Beta</span>
    }
  }

  return (
    <main className='about'>
      {/* <Countdown
        date={new Date('2023-03-01T00:00:00')}
        renderer={renderer}
        className='about__countdown'
      /> */}

      <img className='about__logo' src={logo} alt='logo' />
      <video className='about__video' autoPlay loop muted>
        <source src={bg9} type='video/mp4' />
      </video>
      <div className='about__container'>
        <div className='about__article'>
          {/* <Link to='/register'>
            <button className='about__article__button about__article__button--beta'>
              Join Beta
            </button>
          </Link>
          <a href='https://discord.gg/BaKvVusqvE' target='_blank' rel='noreferrer'>
            <button className='about__article__button about__article__button--discord'>
              Join Discord{' '}
            </button>
          </a> */}

          <div className='about__article__content'>
            <div className='about__article__content__left'>
              <div className='about__article__content__timeline'>
                <div className='about__article__content__image'>
                  <img src={banner} alt='test' />
                </div>
                <p className='about__article__content__date'>Date: Soon</p>
                <h4 className='about__article__content__subtitle'>
                  <a href='https://cabalchronicles.com/article/1' target='_blank' rel='noreferrer'>
                    The Beginning
                  </a>
                </h4>
                <p className='about__article__content__text'>
                  The first patch will be called &quot;Rise of Sigmetal&quot; and it will represent
                  the starting point, nostalgic, early days of the game.
                </p>

                <div className='about__article__content__vertical--arrow'></div>
              </div>

              <div className='about__article__content__timeline'>
                <div className='about__article__content__image'>
                  <img src={mithrilBanner} alt='test' />
                </div>
                <p className='about__article__content__date'>Date: TBA</p>
                <h4 className='about__article__content__subtitle'>Mithril Advancement</h4>
                <p className='about__article__content__text'>
                  Will include the first major update to the game, which will be called
                  &quot;Mithril Advancement&quot; and will include Mithril items and weapons.
                  Maquinas Outpost & FTB2F will be its primary focus.
                </p>

                <div className='about__article__content__vertical--arrow'></div>
              </div>

              <div className='about__article__content__timeline'>
                <div className='about__article__content__image'>
                  <img src={archridiumBanner} alt='test' />
                </div>
                <p className='about__article__content__date'>Date: TBA</p>
                <h4 className='about__article__content__subtitle'>Age of Archridium</h4>
                <p className='about__article__content__text'>
                  In this patch we will introduce a new concept of Mercenaries and Runes alongside
                  everything that comes with Archridium.
                </p>

                <div className='about__article__content__vertical--arrow'></div>
              </div>

              <div className='about__article__content__timeline'>
                <div className='about__article__content__image'>
                  <img src={palladiumBanner} alt='test' />
                </div>
                <p className='about__article__content__date'>Date: TBA</p>
                <h4 className='about__article__content__subtitle'>Palladium Evolution</h4>
                <p className='about__article__content__text'>
                  This patch will introduce new and improved Awakened system dungeons and items.
                </p>

                <div className='about__article__content__vertical--arrow'></div>
              </div>

              <div className='about__article__content__timeline'>
                <div className='about__article__content__image'>
                  <img src={demoniteBanner} alt='test' />
                </div>
                <p className='about__article__content__date'>Date: TBA</p>
                <h4 className='about__article__content__subtitle'>Demonite Dominion</h4>
                <p className='about__article__content__text'>
                  Demonite Dungeons will be introduced in this patch, along with new Demonite items
                  and weapons. We are closing in on the end of the first year of the game.
                </p>

                <div className='about__article__content__vertical--arrow'></div>
              </div>

              <div className='about__article__content__timeline'>
                <div className='about__article__content__image'>
                  <img src={dragoniumBanner} alt='test' />
                </div>
                <p className='about__article__content__date'>Date: TBA</p>
                <h4 className='about__article__content__subtitle'>Dragon&apos;s Reign</h4>
                <p className='about__article__content__text'>
                  This patch will introduce the first Dragonite dungeons and items. We will also
                  introduce the first Dragonite weapons. This will be the last patch of the first
                  year of the game.
                </p>
              </div>
            </div>
            <div className='about__article__content__vertical--separator'></div>
            <div className='about__article__content__body'>
              <img className='about__article__content__body__image' src={bannerAbout} alt='test' />
              <div className='about__article__content__body__wrapper'>
                <p className='about__article__content__title'>Introduction</p>
                <p className='about__article__content__text'>
                  We are a highly skilled and experienced team of three, who have dedicated an
                  extensive amount of time and effort over the past two years to the development of
                  this game. As avid enthusiasts of the Cabal Online franchise, we have been avid
                  players of the game since 2008, amassing over 12 years of experience and insight.
                  During this time, we have observed the evolution of the game, including shifts
                  towards a pay-to-win model and a decline in the community&apos;s overall
                  positivity. Nevertheless, we remain committed to improving the game and fostering
                  a healthier community for all players.
                </p>
              </div>

              <div className='about__article__content__body__wrapper'>
                <p className='about__article__content__title'>Our Intentions</p>
                <div className='about__article__content__text--wrapper'>
                  <p className='about__article__content__text'>
                    Our primary objective is to develop a game that prioritizes player enjoyment and
                    fairness, rather than a pay-to-win model. We are committed to introducing fresh
                    and innovative content and gameplay concepts that are not present in the
                    original game. Our ultimate goal is to provide a level playing field for all
                    players, ensuring equal opportunities to succeed and fully experience all
                    aspects of the game without the need for excessive financial investment.
                  </p>
                  <img className='about__article__content__text__image' src={devil} alt='test' />
                </div>
              </div>

              <div className='about__article__content__body__wrapper'>
                <p className='about__article__content__title'>Server Timeline/Progression</p>
                <p className='about__article__content__text'>
                  We take great pride in announcing that our server is truly unique and
                  one-of-a-kind. We refer to it as a &quot;True Progression Server&quot;, where the
                  content will be carefully curated and released in a specific order. This approach
                  will allow players to fully savor the game experience without feeling overwhelmed
                  by an excessive amount of content. We assure you that the content will be rolled
                  out in a deliberate and orderly manner and only when it is deemed ready. This
                  strategy will provide ample opportunities for new players to catch up and extend
                  the longevity of the server. The content will be released in the following order:
                </p>
                <ul>
                  <li>1.1. Rise of Sigmetal</li>
                  <li>1.2 Mithril Advancement</li>
                  <li>1.3. Age of Archridium</li>
                  <li>1.4. Palladium Evolution</li>
                  <li>1.5. Demonite Dominion</li>
                  <li>1.6. Dragon&apos;s Reign</li>
                </ul>
              </div>

              <div className='about__article__content__body__wrapper'>
                <p className='about__article__content__title'>More on progression</p>
                <p className='about__article__content__countdown'>TRUE PROGRESSION SERVER</p>
                <p className='about__article__content__text'>
                  We recognize that simply limiting content is not a sufficient solution to ensure a
                  balanced and enjoyable game experience. Our goal is to provide an environment
                  where players can fully immerse themselves in the game without feeling overwhelmed
                  by its vastness. To achieve this, we have thoroughly reworked the leveling system
                  and adjusted the difficulty of dungeons.
                </p>
                <p className='about__article__content__text'>
                  The gap between item sets and dungeons has been increased, allowing for a more
                  gradual progression and making it more manageable for new players to catch up
                  while also challenging for more experienced players to progress. Additionally,
                  every update, new players will be provided with bonus items to assist them in
                  catching up with the rest of the community. We have also scheduled regular in-game
                  events to be held every two weeks, providing opportunities for players to advance
                  and stay engaged with the game.
                </p>
              </div>
              <div className='about__article__content__body__wrapper'>
                <p className='about__article__content__title'>Other additions to the game</p>
                <p className='about__article__content__text'>
                  In order to achieve a fully balanced and immersive gameplay experience, certain
                  items have been removed from the game and new ones have been added. One example of
                  this is the revised crafting system for runes, pets, belts, and other custom
                  content, which now require unique materials that can be obtained in specific
                  locations. To fully utilize these new features, players are advised to read the
                  detailed descriptions of each dungeon and its objectives.
                </p>
                <p className='about__article__content__text'>
                  By strategically placing items throughout the game, we aim to increase the value
                  and significance of each item. Another noteworthy change includes the addition of
                  challenging &quot;Awakened Dungeons&quot; that offer substantial rewards for
                  players who conquer them.
                </p>
                <p className='about__article__content__text'>
                  As for future developments, we are actively exploring options for custom
                  &quot;Overlord levels&quot; and achievement systems to continue to provide a
                  challenging and engaging experience for players once all the content has been
                  released.
                </p>
              </div>
              <div className='about__article__content__body__wrapper'>
                <p className='about__article__content__title'>What is the end content?</p>
                <p className='about__article__content__text'>
                  The progression of content release is dependent on the patch currently being
                  played. As a true progression server, we will be releasing content in a specific
                  order, with the endgame being the &quot;Dragon&apos;s Reign&quot; patch, the final
                  release by Cabal Chronicles. Our focus is on highlighting the importance of each
                  item set, dungeon, and item in the game, in order to provide a comprehensive and
                  enjoyable experience without the feeling of being overwhelmed by the vastness of
                  content.
                </p>
                <p className='about__article__content__text'>
                  With each release, players can expect to find a{' '}
                  <a href='https://cabalchronicles.com/article/1' target='_blank' rel='noreferrer'>
                    patch note
                  </a>{' '}
                  detailing the changes and additions to the game, as well as a detailed description
                  of each dungeon and its objectives.
                </p>
                <p className='about__article__content__text'>
                  The current patch, <strong>&quot;1.1. Rise of Sigmetal,&quot;</strong> is the
                  first release by Cabal Chronicles and serves as the foundation of the game, while
                  the next patch, &quot;1.2. Mithril Advancement,&quot; will be a major update that
                  will add a significant amount of new content to the game and will be released
                  soon. This patch will include everything up to and including Sigmetal sets and
                  dungeons.
                </p>
              </div>
              <div className='about__article__content__body__wrapper'>
                <img
                  src={verticalBanner}
                  alt='vertical banner'
                  className='about__article__content__body__wrapper--image'
                />
                <p className='about__article__content__title'>Mission War Rewards</p>
                <p className='about__article__content__text'>
                  We are placing a significant emphasis on the Mission War feature, in conjunction
                  with the dungeons, as a key aspect of the game. The PVE and PVP elements have
                  always coexisted and we are working towards balancing both aspects to cater to
                  players who enjoy PVP.
                </p>
                <p className='about__article__content__text'>
                  Currently, we are in the process of revamping the PVP system, specifically
                  focusing on the Mission War feature. As Cabal Online does not have an Arena
                  system, we plan to adjust these game systems to enhance the PVP scene.
                </p>
                <p className='about__article__content__text'>
                  In the meantime, we plan to introduce two different types of Mission Wars, each
                  with their own unique rewards. One type will provide players with equal gear to
                  showcase their skills, while the other type will allow players to participate with
                  their own gear and demonstrate their abilities.
                </p>
                <p className='about__article__content__text'>
                  Rewards for participating in the Mission War will be as follows:
                </p>
                <table className='about__article__content__table'>
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Item</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>eCoins</td>
                      <td>1000</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>eCoins</td>
                      <td>700</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>eCoins</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>eCoins</td>
                      <td>300</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>eCoins</td>
                      <td>100</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AboutPage
