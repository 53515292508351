import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../api/instances/createConnections'
import { errorToast, successToast } from '../../components/ToastContainer/ToastContainer'
import { ContainerLoading } from '../../components/ContainerLoading/ContainerLoading'
import './ConfirmEmail.scss'

/**
 * ConfirmEmail page visible
 * when the user clicks on the
 * confirmation link in the email
 * @returns JSX
 */
const ConfirmEmail = () => {
  const params = useParams()
  const navigate = useNavigate()
  const token = params.token as string

  const [status, setStatus] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [newEmail, setNewEmail] = useState<any>(null)

  const isUpdate = window.location.href.includes('update')
  /**
   * @description Resend the confirmation email
   * @returns void
   */
  const resendEmail = async () => {
    if (!newEmail) {
      errorToast('You must enter an email to resend an email')
      return
    }

    try {
      const response = await api.client.sendConfirmEmail(newEmail, 'confirm')
      if (response.error) {
        errorToast(response.error)
      }
      if (response.success) {
        successToast(response.success)
      }
    } catch (error) {
      console.log(error)
      errorToast('Something went wrong')
    }
  }

  /**
   * @description Verify the token
   */
  const verifyToken = async () => {
    setLoading(true)
    try {
      const response = await api.client.confirmEmail(token as string)
      if (response.error) {
        setStatus(response)
        errorToast(response.error)
      }
      if (response.success) {
        setStatus(response)
        successToast(response.success)
        setTimeout(() => {
          navigate('/login')
        }, 5000)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    verifyToken()
  }, [])

  return (
    <>
      <div className='confirm-email'>
        <p className='confirm-email__title'>Confirm Email</p>
        <div className='confirm-email__container'>
          {loading ? (
            <ContainerLoading />
          ) : (
            <p className='confirm-email__container--result'>
              {status.error || status.success || 'Something went wrong'}
            </p>
          )}
          {status.success && (
            <p className='confirm-email__container--message'>
              You will be redirected to the login page in 5 seconds.
            </p>
          )}
          {!status.success && !isUpdate && (
            <div className='confirm-email__container--button-container'>
              <p className='confirm-email__container--message'>
                Do you want to receive a new confirmation email?
              </p>
              <section className='confirm-email__container--button-container--buttons'>
                <input
                  type='email'
                  className='confirm-email__container--button-container--buttons--email'
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <button
                  className='confirm-email__container--button-container--buttons--resend'
                  onClick={resendEmail}
                >
                  Resend
                </button>
              </section>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ConfirmEmail
