import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthGuard } from '../components/AuthGuard/AuthGuard'
import HomePage from '../pages/HomePage/HomePage'
import RegisterPage from '../pages/registerPage/RegisterPage'
import LoginPage from '../pages/LoginPage/LoginPage'
import Dashboard from '../pages/Dashboard/Dashboard'
import NavBar from '../components/NavBar/NavBar'
import Footer from '../components/Footer/Footer'
import AboutPage from '../pages/About/AboutPage'
import RankingsPage from '../pages/Rankings/Rankings'
import ToS from '../pages/ToS/ToS'
import ConfirmEmail from '../pages/ConfirmEmail/ConfirmEmail'
import ResetPassword from '../pages/ResetPassword/ResetPassword'
import ResetPasswordForm from '../pages/ResetPassword/ResetPasswordForm'
import ArticlePage from '../pages/ArticlePage/ArticlePage'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import Privacy from '../pages/Privacy/Privacy'
import AgreementTerms from '../pages/AgreementTerms/AgreementTerms'

const PublicRoutes = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        {/**
         * Protected Routes
         */}
        <Route
          path='/dashboard'
          element={
            <AuthGuard>
              <Dashboard />
            </AuthGuard>
          }
        />

        {/**
         * Unprotected Routes
         */}
        <Route path='/' element={<HomePage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/rankings' element={<RankingsPage />} />
        <Route path='/tos' element={<ToS />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/agreement-terms' element={<AgreementTerms />} />
        <Route path='/confirm/:token' element={<ConfirmEmail />} />
        <Route path='/update/:token' element={<ConfirmEmail />} />
        <Route path='/reset/:token' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ResetPasswordForm />} />
        <Route path='/article/:id' element={<ArticlePage />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default PublicRoutes
