import React from 'react'
import ReactDOM from 'react-dom/client'
import Toast from './components/ToastContainer/ToastContainer'
import { PageRunner } from './components/PageRunner/PageRunner'
import PublicRoutes from './routes/routes'
import DataLayerComponent from './store/DataLayer'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './styles/global.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <DataLayerComponent>
    <Toast />
    <PageRunner />
    <PublicRoutes />
  </DataLayerComponent>,
)
