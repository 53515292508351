import { Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import api from '../../api/instances/createConnections'
import { useSelector } from 'react-redux'
import {
  errorToast,
  successToast,
  warningToast,
} from '../../components/ToastContainer/ToastContainer'
import { Account } from '../../types/interfaces'
import xtremetop from '../../assets/images/1.png'
import topg from '../../assets/images/2.png'
import arenatop from '../../assets/images/3.png'
import gtop100 from '../../assets/images/4.png'
import './VoteModal.scss'

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const VoteModal = (props: ModalProps) => {
  const { show, handleClose } = props

  const account: Account = useSelector((state: any) => state.user.account.user)

  useEffect(() => {
    if ((!account?.Verified || account?.Verified == 0) && show) {
      warningToast('You need to verify your account to vote')
      handleClose()
    }
  }, [show])

  const handleVote = async (id: string) => {
    try {
      const response = await api.cash.voteEcoins(account.ID, Number(id))
      if (response.error) {
        return errorToast(response.error)
      }
      successToast('Thank you for voting!')
    } catch (error) {
      console.error(error)
      errorToast('Something went wrong')
    }
  }

  return (
    <Modal show={show} onHide={handleClose} className='vote'>
      <Modal.Header closeButton className='vote__header'>
        <Modal.Title className='vote__title'>Vote</Modal.Title>
      </Modal.Header>
      <Modal.Body className='vote__body'>
        <section className='vote__container'>
          <div className='vote__content'>
            <div className='vote__content__item'>
              <p className='vote__content__item__title'>Xtremetop100</p>
              <p className='vote__content__item__description'>You will receive 100 Ecoin</p>
              <a
                href='https://www.xtremetop100.com/in.php?site=1132373816'
                target='_blank'
                rel='noreferrer'
              >
                <img src={xtremetop} alt='xtremetop' onClick={() => handleVote('1')} />
              </a>
            </div>
            <div className='vote__content__item'>
              <p className='vote__content__item__title'>TopG</p>
              <p className='vote__content__item__description'>You will receive 100 Ecoin</p>
              <a
                href='https://topg.org/cabal-private-servers/server-651120'
                target='_blank'
                rel='noreferrer'
              >
                <img src={topg} alt='topG' onClick={() => handleVote('2')} />
              </a>
            </div>
            <div className='vote__content__item'>
              <p className='vote__content__item__title'>Arena Top</p>
              <p className='vote__content__item__description'>You will receive 100 Ecoin</p>
              <a
                href='https://www.arena-top100.com/index.php?a=in&u=mirceagab'
                target='_blank'
                rel='noreferrer'
              >
                <img src={arenatop} alt='arenatop' onClick={() => handleVote('3')} />
              </a>
            </div>
            <div className='vote__content__item'>
              <p className='vote__content__item__title'>gTop100</p>
              <p className='vote__content__item__description'>You will receive 100 Ecoin</p>
              <a
                href='https://gtop100.com/topsites/Cabal-Online/sitedetails/Cabal-Chronicles-101669?vote=1'
                target='_blank'
                rel='noreferrer'
              >
                <img src={gtop100} alt='gtop100' onClick={() => handleVote('4')} />
              </a>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  )
}

export default VoteModal
