import bg9 from '../../assets/video/bg9.mp4'
import './styles.scss'

const VideoBG = (): JSX.Element => {
  return (
    <video className='background__video' autoPlay loop muted>
      <source src={bg9} type='video/mp4' />
    </video>
  )
}

export default VideoBG
