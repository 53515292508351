import './ContentLoader.scss'

interface Props {
  theme: 'light' | 'dark'
  size: 'small' | 'large'
}

export const ContentLoader = (props: Props) => {
  return <div className={`small-spinner ${props.theme} ${props.size}`}></div>
}
