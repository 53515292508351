import { Rest } from '../baseApiRest/rest'

export class NewsApi extends Rest {
  constructor(baseUrl: string) {
    super(baseUrl)
  }

  public async fetchNews() {
    try {
      const request = await this.request('GET', '/news/all', {})
      return request
    } catch (err) {
      console.error(err)
    }
  }

  // /**
  //  * Create a new article
  //  * @param title
  //  * @param author
  //  * @param type
  //  * @param body
  //  */
  // public async createArticle(title: string, author: string, ntype: string, body: string) {
  //   try {
  //     const request = await this.request('POST', '/news/create', {
  //       title,
  //       body,
  //       author,
  //       ntype,
  //     })
  //     return request
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }
}
