import { setData } from '../../store/server/server'
import { setNews } from '../../store/server/news'
import api from './createConnections'

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(navigator.userAgent)) {
  window.location.href = 'https://m.cabalchronicles.com'
}

/**
 * @description This function is used to get the account data from the API and store it in the redux store
 * @param dispatch
 */
const pageRun = async (dispatch: any) => {
  try {
    // const onlinePlayers = await api.characters.getAllOnlineCharacters();
    // const allAccounts = await api.client.getAllAccounts();
    const allNews = await api.news.fetchNews()
    const FCO = await api.characters.getForceCaliburOwner()
    // const capellaPlayersTotal = await api.characters.characterNationCapella();
    // const capellaPlayersOnline = await api.characters.characterOnNationCapella();
    // const procyonPlayersTotal = await api.characters.characterNationProcyon();
    // const procyonPlayersOnline = await api.characters.characterOnNationProcyon();
    // const allCharacters = await api.characters.getAllCharacters();
    // const neutralPlayersTotal = await api.characters.countNeutralChars();
    // const neutralPlayersOnline = await api.characters.countOnNeutralChars();
    const topCharacters = await api.characters.getTopPlayer()
    const capellaBringer = await api.characters.capellaBringer()
    const procyonBringer = await api.characters.procyonBringer()
    // const top10Online = await api.characters.getTop10Online();
    const allGuilds = await api.characters.getAllGuilds()
    const top10Reputation = await api.characters.getTop10Reputation()
    const top10Level = await api.characters.getTop10Level()
    const top10Alz = await api.characters.getTop10Alz()

    dispatch(
      setNews({
        news: allNews,
        hasData: true,
      }),
    )

    dispatch(
      setData({
        FCO: FCO || [],
        topCharacter: topCharacters || [],
        capellaBringer: capellaBringer || [],
        procyonBringer: procyonBringer || [],
        allGuilds: allGuilds || [],
        top10Reputation: top10Reputation || [],
        top10Level: top10Level || [],
        top10Alz: top10Alz || [],
        hasData: true,
      }),
    )
  } catch (err) {
    console.error(err)
  }
}

export default pageRun
