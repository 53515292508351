export const today = new Date().toISOString().split('T')[0]
export const dateToString = (date: Date) => date.toISOString().split('T')[0]
import moment from 'moment-timezone'

export const formatDate = (date: string) => {
  if (!date) {
    return 'Unknown'
  }
  const dateObj = new Date(date)
  return dateObj.toISOString().split('T')[0]
}

export const serverTime = new Date().toLocaleString('en-US', {
  timeZone: 'Asia/Jakarta',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
})

export const warTime = () => {
  const now = moment().tz('Asia/Jakarta')

  const eventTimes = [6, 12, 18, 24]

  if (now.hour() < eventTimes[0]) {
    return '06:00 AM'
  }

  if (now.hour() < eventTimes[1] && now.hour() >= eventTimes[0]) {
    return '12:00 PM'
  }

  if (now.hour() < eventTimes[2] && now.hour() >= eventTimes[1]) {
    return '06:00 PM'
  }

  if (now.hour() < eventTimes[3] && now.hour() >= eventTimes[2]) {
    return '12:00 AM'
  }

  return 'Soon'
}

warTime()

export const questionTypes = (type: string) => {
  switch (type) {
    case 'cityBorn':
      return 'In what city were you born?'
    case 'favoritePet':
      return 'What is the name of your favorite pet?'
    case 'motherMaidenName':
      return 'What is your mother’s maiden name?'
    case 'highSchool':
      return 'What is the name of your high school?'
    case 'elementarySchool':
      return 'What is the name of your elementary school?'
    case 'firstCar':
      return 'What was the make and model of your first car?'
    case 'favoriteFood':
      return 'What is your favorite food as a child?'
    default:
      return 'Unknown'
  }
}

export const nationCharacter = (nation: string) => {
  switch (nation) {
    case '0':
      return 'Neutral'
    case '1':
      return 'Capella'
    case '2':
      return 'Procyon'
    default:
      return 'Neutral'
  }
}

export const formatLargeNumbers = (num: string) => {
  if (num.length > 3 && num.length <= 6) {
    return `${num.slice(0, -3)}K`
  }
  if (num.length > 6 && num.length <= 9) {
    return `${num.slice(0, -6)}M`
  }
  if (num.length > 9 && num.length <= 12) {
    return `${num.slice(0, -9)}B`
  }
  if (num.length > 12 && num.length <= 15) {
    return `${num.slice(0, -12)}T`
  }
  if (num.length > 15 && num.length <= 18) {
    return `${num.slice(0, -15)}Q`
  }

  return num
}

export const returnClassName = (className: string) => {
  switch (className) {
    case '0':
      return 'Warrior'
    case '1':
      return 'Wizzard'
    case '2':
      return 'Force Shielder'
    case '3':
      return 'Gladiator'
    case '4':
      return 'Blader'
    case '5':
      return 'Force Archer'
    case '6':
      return 'Force Blader'
    case '7':
      return 'Force Gunner'
    case '8':
      return 'Dark Mage'
    default:
      return 'Unknown'
  }
}
