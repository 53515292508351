import { useState } from 'react'
import api from '../../api/instances/createConnections'
import { ContentLoader } from '../../components/ContentLoader/ContentLoader'
import { errorToast, successToast } from '../../components/ToastContainer/ToastContainer'
import './ResetPassword.scss'

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!email) {
      setError('Please enter your email')
      setSuccess(null)
      return
    }

    try {
      setLoading(true)
      const response = await api.client.sendResetEmail(email)
      if (response.error) {
        setError(response.error)
        setSuccess(null)
        errorToast('Something went wrong! Please try again later')
      }
      if (response.success) {
        setSuccess(response.success)
        setError(null)
        successToast('Email sent successfully')
      }
    } catch (error) {
      console.error(error)
      setError('Something went wrong!')
      setSuccess(null)
    }
    setLoading(false)
  }
  return (
    <div className='reset-password'>
      <div className='reset-password__container'>
        <form className='reset-password__form' onSubmit={handleSubmit}>
          <div className='reset-password__group'>
            <input
              className='reset-password__input'
              placeholder='Email'
              type='email'
              name='email'
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            type='submit'
            className={loading ? 'register-form__button--disabled' : 'register-form__button'}
          >
            {loading ? <ContentLoader theme='light' size='large' /> : 'Send Reset Email'}
          </button>
          {error && <p className='reset-password__error'>{error}</p>}
          {success && <p className='reset-password__success'>{success}</p>}
        </form>
      </div>
    </div>
  )
}

export default ResetPasswordForm
