import { useState } from 'react'
import './Tabs.scss'
interface Props {
  tabs: string[]
  content: any[]
}

const Tabs = (props: Props) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className='tabs'>
      <div className='tabs__container'>
        {props.tabs.map((tab, index) => (
          <div
            className={`tabs__tab ${activeTab === index ? 'tabs__tab--active' : ''}`}
            onClick={() => setActiveTab(index)}
            key={index}
          >
            {tab}
          </div>
        ))}
      </div>
      {<div className='tabs__content'>{props.content[activeTab]}</div>}
    </div>
  )
}

export default Tabs
