import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import capellaLogo from '../../assets/images/capella.png'
import procyonLogo from '../../assets/images/procyon.png'
import fcoLogo from '../../assets/images/fco.png'
import { warTime } from '../../utils/constants/constants'
import './WarStatus.scss'
import { ContentLoader } from '../../components/ContentLoader/ContentLoader'

const WarStatus = () => {
  const capellaBringer = useSelector((state: any) => state.server.capellaBringer)
  const procyonBringer = useSelector((state: any) => state.server.procyonBringer)
  const FCO = useSelector((state: any) => state.server.FCO)
  const [fco, setFCO] = useState<any>(null)
  const [capella, setCapella] = useState<any>(null)
  const [procyon, setProcyon] = useState<any>(null)
  const [timer, setWarTime] = useState<any>(null)

  setInterval(() => {
    setWarTime(warTime)
  }, 1000)

  useEffect(() => {
    if (capellaBringer.characters && procyonBringer.characters) {
      setCapella(capellaBringer.characters[0])
      setProcyon(procyonBringer.characters[0])
    }

    if (FCO.characters) {
      setFCO(FCO.characters[0])
    }
  }, [capellaBringer, procyonBringer, FCO])

  return (
    <div className='war-status'>
      <div className='war-status__wrapper'>
        <section className='war-status__bringer'>
          <div className='war-status__container fco'>
            <img src={fcoLogo} alt='fco' />
            <div>
              <p className='war-status__fco__title'>ForceCalibur Owner</p>
              <div className='war-status__fco__name'>
                {fco ? fco.Name : <ContentLoader theme='light' size='large' />}
              </div>
            </div>
          </div>
          <div className='war-status__container capella'>
            <img src={capellaLogo} alt='capella' />
            <div>
              <p className='war-status__capella__title'>Luminosity Bringer</p>
              <div className='war-status__capella__name'>
                {capella ? capella.Name : <ContentLoader theme='light' size='large' />}
              </div>
            </div>
          </div>
          <div className='war-status__container procyon'>
            <img src={procyonLogo} alt='procyon' />
            <div>
              <p className='war-status__procyon__title'>Storm Bringer</p>
              <div className='war-status__procyon__name'>
                {procyon ? procyon.Name : <ContentLoader theme='light' size='large' />}
              </div>
            </div>
          </div>
          <div className='war-status__timer'>
            <p className='war-status__timer__title'>Next War</p>
            <p className='war-status__timer__time'>{timer}</p>
          </div>
        </section>
      </div>
    </div>
  )
}

export default WarStatus
