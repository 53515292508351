import { useForm } from 'react-hook-form'
import './FormGenerator.scss'

interface Config {
  label?: string
  type: string
  name: string
  value?: string
  placeholder?: string
  validation?: any
  disabled?: boolean
  className?: string
}

interface Props {
  onSubmit: (data: any) => void
  configs: Config[]
}

export const FormGenerator = ({ configs, onSubmit }: Props) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm()

  const isValid = Object.keys(errors).length === 0
  const fieldsWatched = watch()

  return (
    <form onSubmit={handleSubmit(() => onSubmit(fieldsWatched))} className='form-generator'>
      {configs.map((config: Config) => (
        <div key={config.name} className='form-generator'>
          {errors[config.name] && <span className='input-error'>This field is required</span>}
          {config.type && (
            <>
              <input
                disabled={config.disabled}
                type={config.type}
                placeholder={config.placeholder}
                className={`w-100 input-field ${config.className}`}
                value={config.value}
                {...register(config.name, config.validation)}
              />
            </>
          )}
        </div>
      ))}
      <input type='submit' disabled={!isValid} className='form-button' value='Submit' />
    </form>
  )
}
