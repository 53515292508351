import { Character } from '../../types/interfaces'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ContainerLoading } from '../../components/ContainerLoading/ContainerLoading'
import { formatLargeNumbers, returnClassName } from '../../utils/constants/constants'
import pageRun from '../../api/instances/pageLoad'

interface Props {
  type: string
  key: string
}

const CharacterRanking = (props: Props) => {
  const { type } = props
  const dispatch = useDispatch()
  const top10Reputation = useSelector((state: any) => state.server.top10Reputation)
  const top10Level = useSelector((state: any) => state.server.top10Level)
  const top10Alz = useSelector((state: any) => state.server.top10Alz)
  const [characters, setCharacters] = useState<any>(null)

  // while the page is visible, every 1 minute, run pageRun
  // add event listener to page visibility

  const handleVisibilityChange = () => {
    console.log('visibility change')
    if (document.visibilityState === 'visible') {
      // set interval to run pageRun every 1 minute
      const interval = setInterval(() => {
        pageRun(dispatch)
      }, 60000)
      return () => {
        clearInterval(interval)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange, false)
    }
  }, [])

  useEffect(() => {
    if (top10Reputation.characters && top10Level.characters && top10Alz.characters) {
      if (type === 'Reputation') {
        setCharacters(top10Reputation.characters)
      } else if (type === 'LEV') {
        setCharacters(top10Level.characters)
      } else if (type === 'Alz') {
        setCharacters(top10Alz.characters)
      }
    }
  }, [top10Reputation, top10Level, top10Alz, type])

  if (!characters) return <ContainerLoading />

  return (
    <>
      <div className='rankings__table__header'>
        <div className='rankings__table__header__rank'>Rank</div>
        <div className='rankings__table__header__name'>Name</div>
        <div className='rankings__table__header__class'>Class</div>
        <div className='rankings__table__header__level'>Level</div>
        <div className='rankings__table__header__rep'>Reputation</div>
        <div className='rankings__table__header__alz'>Alz</div>
      </div>
      <div className='rankings__table__body'>
        {characters.map((character: Character, index: number) => {
          return (
            <div className='rankings__table__body__row' key={index}>
              <div className='rankings__table__body__row__rank'>{index + 1}</div>
              <div className='rankings__table__body__row__name'>{character.Name}</div>{' '}
              <div className='rankings__table__body__row__class'>
                {returnClassName(character.ClassStyle)}
              </div>
              <div className='rankings__table__body__row__level'>{character.LEV}</div>
              <div className='rankings__table__body__row__rep'>{character.Reputation}</div>
              <div className='rankings__table__body__row__alz'>
                {formatLargeNumbers(character.Alz)} Alz
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CharacterRanking
