interface ButtonProps {
  text: string
  onClick?: () => void
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  url?: string
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  className,
  disabled,
  type,
  url,
}: ButtonProps): JSX.Element => {
  const button = (
    <button
      className={className ? `context__button--${className}` : 'context__button'}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {text}
    </button>
  )

  return (
    <>
      {url ? (
        <a href={url} target='_blank' rel='noreferrer'>
          {button}
        </a>
      ) : (
        button
      )}
    </>
  )
}

export default Button
