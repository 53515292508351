import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import pageRun from '../../api/instances/pageLoad'
import accountStorePiece from '../../api/instances/accountData'

/**
 * @description Page runner for initial page load
 * @returns null
 */
export const PageRunner = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn)
  const account = useSelector((state: any) => state.user.account)
  const hasData = useSelector((state: any) => state.server.hasData)

  /**
   * If all server data has not been fetched, fetch it and save it to redux
   */
  useEffect(() => {
    if (!hasData) {
      pageRun(dispatch)
    }
  }, [hasData])

  /**
   * If user is logged in, get account data
   */
  useEffect(() => {
    if (isLoggedIn && account.user.ID) {
      accountStorePiece(dispatch, account.user.ID)
    }
  }, [isLoggedIn && account.ID])

  return null
}
