import Tabs from '../../components/Tabs/Tabs'
import CharacterRanking from './Characters'
import GuildRanking from './Guilds'
import './Rankings.scss'
import WarStatus from '../WarStatus/WarStatus'
import bg9 from '../../assets/video/bg9.mp4'

const RankingsPage = () => {
  const tabs = ['Reputation', 'Level', 'ALZ', 'Guilds']
  const content = [
    <CharacterRanking key='1' type='Reputation' />,
    <CharacterRanking key='2' type='LEV' />,
    <CharacterRanking key='3' type='Alz' />,
    <GuildRanking key='4' />,
  ]

  return (
    <div className='rankings'>
      <video className='rankings__video' autoPlay loop muted>
        <source src={bg9} type='video/mp4' />
      </video>
      <div className='rankings__table'>
        <div className='rankings__tabs'>
          <Tabs tabs={tabs} content={content} />
        </div>
        <div className='rankings__content'>
          <WarStatus />
        </div>
      </div>
    </div>
  )
}

export default RankingsPage
