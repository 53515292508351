import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import './Footer.scss'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer__logo'>
          <Link to='/'>
            <img src={logo} alt='Cabal Chronicles Logo' loading='lazy' />
          </Link>
        </div>
        <div className='footer__links'>
          <Link to='/tos'>Terms of service</Link>
          <Link to='/privacy'>Privacy policy</Link>
          <Link to='/agreement-terms'>Agreement terms</Link>
          <p>© Cabal Chronicles All Rights Reserved.</p>

          <a href='https://github.com/MirceaDumitrescu' target='_blank' rel='noreferrer'>
            Website created by <span>Mircea Dumitrescu</span>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
