import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ContainerLoading } from '../../components/ContainerLoading/ContainerLoading'

const GuildRanking = () => {
  const reduxGuilds: any = useSelector((state: any) => state.server.allGuilds)
  const [guilds, setGuilds] = useState([])
  const getTop10Guilds = () => {
    const top10Guilds = reduxGuilds.characters.slice(0, 10)
    setGuilds(top10Guilds)
  }
  useEffect(() => {
    if (reduxGuilds.characters) {
      getTop10Guilds()
    }
  }, [reduxGuilds.characters])

  if (!guilds.length) return <ContainerLoading />

  return (
    <>
      {' '}
      <div className='rankings__table__header'>
        <div className='rankings__table__header__rank'>Rank</div>
        <div className='rankings__table__header__name'>Name</div>
        <div className='rankings__table__header__level'>Level</div>
        <div className='rankings__table__header__class'>Points</div>
        <div className='rankings__table__header__rank'>Created</div>
        <div className='rankings__table__header__exp'>GameMaster</div>
      </div>
      <div className='rankings__table__body'>
        {guilds.map((guild: any, index: number) => {
          return (
            <div className='rankings__table__body__row' key={index}>
              <div className='rankings__table__body__row__rank'>{index + 1}</div>
              <div className='rankings__table__body__row__name'>{guild.Name}</div>
              <div className='rankings__table__body__row__level'>{guild.Level}</div>
              <div className='rankings__table__body__row__points'>{guild.Points}</div>
              <div className='rankings__table__body__row__createDate'>{guild.CreateDate}</div>
              <div className='rankings__table__body__row__gameMaster'>{guild.GameMaster}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default GuildRanking
