import { Rest } from '../baseApiRest/rest'

export class CharacterApi extends Rest {
  constructor(baseUrl: string) {
    super(baseUrl)
  }

  /**
   * Get top 100 characters
   */
  getAllCharacters = async () => {
    try {
      const response = await this.request('GET', '/char/getChars')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all characters
   * Top 10
   */
  getAllCharactersCapella = async () => {
    try {
      const response = await this.request('GET', '/char/getCapella')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all Procyon characters
   * Top 10
   */
  getAllCharactersProcyon = async () => {
    try {
      const response = await this.request('GET', '/char/getProcyon')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all characters ADMIN
   * Top 10
   */
  getAllCharactersAdmin = async () => {
    try {
      const response = await this.request('GET', '/char/getAdmin')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all online characters
   */
  getAllOnlineCharacters = async () => {
    try {
      const response = await this.request('GET', '/char/getOnline')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get Force Calibur Owner
   */
  getForceCaliburOwner = async () => {
    try {
      const response = await this.request('GET', '/char/getFCowner')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * countNeutralChars
   */

  countNeutralChars = async () => {
    try {
      const response = await this.request('GET', '/char/countNeutralChars')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * countOnNeutralChars
   */
  countOnNeutralChars = async () => {
    try {
      const response = await this.request('GET', '/char/countOnNeutralChars')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * characterNationCapella
   * Get all characters on Capella
   */
  characterNationCapella = async () => {
    try {
      const response = await this.request('GET', '/char/characterNationCapella')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * characterNationProcyon
   * Get all characters on Procyon
   */
  characterNationProcyon = async () => {
    try {
      const response = await this.request('GET', '/char/characterNationProcyon')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * characterOnNationProcyon
   * Get all Procyon characters online
   */
  characterOnNationProcyon = async () => {
    try {
      const response = await this.request('GET', '/char/characterOnNationProcyon')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * characterOnNationProcyon
   * Get all Capella characters online
   */

  characterOnNationCapella = async () => {
    try {
      const response = await this.request('GET', '/char/characterOnNationCapella')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * getTopPlayer
   */
  getTopPlayer = async () => {
    try {
      const response = await this.request('GET', '/char/getTopPlayer')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * capellaBringer
   */
  capellaBringer = async () => {
    try {
      const response = await this.request('GET', '/char/capellaBringer')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * procyonBringer
   */
  procyonBringer = async () => {
    try {
      const response = await this.request('GET', '/char/procyonBringer')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * getTop10Online
   */
  getTop10Online = async () => {
    try {
      const response = await this.request('GET', '/char/getTop10Online')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all guilds
   */
  getAllGuilds = async () => {
    try {
      const response = await this.request('GET', '/char/getGuilds')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get top 10 based on reputation
   */
  getTop10Reputation = async () => {
    try {
      const response = await this.request('GET', '/char/getTop10Reputation')
      return response
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Get top 10 based on level
   */
  getTop10Level = async () => {
    try {
      const response = await this.request('GET', '/char/getTop10Level')
      return response
    } catch (err) {
      console.error(err)
    }
  }
  /**
   * Get top 10 based on alz
   */
  getTop10Alz = async () => {
    try {
      const response = await this.request('GET', '/char/getTop10Alz')
      return response
    } catch (err) {
      console.error(err)
    }
  }
}
