import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api/instances/createConnections'
import {
  errorToast,
  successToast,
  warningToast,
} from '../../components/ToastContainer/ToastContainer'
import { pwdValidate } from '../../utils/validations/validations'
import bg9 from '../../assets/video/bg9.mp4'
import './RegisterPage.scss'
import { ContentLoader } from '../../components/ContentLoader/ContentLoader'

const RegisterPage = () => {
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedSecretQuestion, setSelectedSecretQuestion] = useState<string>('cityBorn')
  const navigate = useNavigate()

  const loggedIn = useSelector((state: any) => state.user.isLoggedIn)

  useEffect(() => {
    if (loggedIn) {
      navigate('/')
    }
  }, [loggedIn, navigate])

  const handleSecretQuestion = (e: any) => {
    setSelectedSecretQuestion(e.target.value)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const user = {
      username: e.target.username.value,
      email: e.target.email.value,
      password: e.target.password.value,
      password2: e.target['confirm-password'].value,
      question: selectedSecretQuestion,
      answer: e.target['secret-answer'].value,
    }

    if (!user.username) {
      setError('Username is required')
      errorToast('Username is required')
      return
    }

    if (!user.email) {
      setError('Email is required')
      errorToast('Email is required')
      return
    }

    if (!user.answer) {
      setError('Secret answer is required')
      errorToast('Secret answer is required')
      return
    }

    if (!pwdValidate(user.password)) {
      setError('Password must be at least 8 characters long')
      errorToast('Password must be at least 8 characters long')
      return
    }

    if (user.password !== user.password2) {
      setError('Passwords do not match')
      errorToast('Passwords do not match')
      return
    }

    try {
      setLoading(true)
      const response: any = await api.client.registerAccount(user)
      if (response?.errors) {
        setError(response?.errors[0])
        errorToast(response?.errors[0])
        return
      }

      if (response?.success) {
        setError('')
        successToast('Account created successfully')
        warningToast('Please check your SPAM folder for the confirmation email')
        navigate('/login')
        await api.client.sendConfirmEmail(user.email, 'confirm')
      }
    } catch (err) {
      setError('Something went wrong')
      errorToast('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <main className='register'>
      <form className='register-form' onSubmit={handleSubmit}>
        <p className='register-form__error'>{error}</p>
        <h2 className='register-form__heading'>Create an Account</h2>
        <div className='register-form__input'>
          <input
            type='text'
            id='username'
            name='username'
            className='register-form__input'
            placeholder='Username'
            required
          />
        </div>
        <div className='register-form__input'>
          <input
            type='email'
            id='email'
            name='email'
            className='register-form__input'
            placeholder='Email'
            required
          />
        </div>
        <div className='register-form__input'>
          <input
            type='password'
            id='password'
            name='password'
            className='register-form__input'
            placeholder='Password'
            required
          />
        </div>
        <div className='register-form__input'>
          <input
            type='password'
            id='confirm-password'
            name='confirm-password'
            className='register-form__input'
            placeholder='Confirm Password'
            required
          />
        </div>
        <div className='register-form__input'>
          <select
            id='select-question'
            value={selectedSecretQuestion}
            onChange={handleSecretQuestion}
          >
            <option value='cityBorn'>In what city were you born?</option>
            <option value='favoritePet'>What is the name of your favorite pet?</option>
            <option value='motherMaidenName'>What is your mother&apos;s maiden name?</option>
            <option value='highSchool'>What high school did you attend?</option>
            <option value='elementarySchool'>What was the name of your elementary school?</option>
            <option value='firstCar'>What was the make of your first car?</option>
            <option value='favoriteFood'>What was your favorite food as a child?</option>
          </select>
        </div>
        <div className='register-form__input'>
          <input
            type='text'
            id='secret-answer'
            name='secret-answer'
            className='register-form__input'
            placeholder='Secret Answer'
            required
          />
        </div>
        <button
          type='submit'
          className={loading ? 'register-form__button--disabled' : 'register-form__button'}
        >
          {loading ? <ContentLoader theme='light' size='large' /> : 'Register'}
        </button>
        <Link to={'/login'} className='register-form__login-btn'>
          Already have an account? Login here
        </Link>
      </form>
      <video className='register__video' autoPlay loop muted>
        <source src={bg9} type='video/mp4' />
      </video>
    </main>
  )
}

export default RegisterPage
