import { successToast } from '../../components/ToastContainer/ToastContainer'
import { hasError, startLoading, accountsSuccess } from '../../store/user/actions/getAccounts'
import { setUser } from '../../store/user/user'
import { UserAccount } from '../../types/interfaces'
import { Rest } from '../baseApiRest/rest'

export class AccountApi extends Rest {
  constructor(baseUrl: string) {
    super(baseUrl)
  }

  /**
   *
   * @param data which is an object of type UserAccount
   * @returns Promise<void>
   * and it is used to register a new user
   */
  async registerAccount(data: UserAccount): Promise<void> {
    /**
     * Check if data is valid
     */
    if (!data) {
      throw new Error('Invalid data')
    }

    try {
      const response = await this.request('POST', '/auth/register', data)
      return response
      // await api.fetch.getAllAccounts()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   *
   * @param email
   * @param password
   * @returns Promise<any>
   * It is used to login a user
   * It sets token in cookies
   * It sets user in session storage
   */
  async login(username: string, password: string) {
    AccountApi.store.dispatch(startLoading())
    try {
      const response = await this.request('POST', '/auth/login', { username, password })
      if (response.error) {
        console.error('Error: ', response.error)
        AccountApi.store.dispatch(hasError(true))
        return response
      }
      AccountApi.cookies.set('token', response.token, { path: '/' })
      AccountApi.store.dispatch(accountsSuccess(response))
      sessionStorage.setItem('user', JSON.stringify(response))
      AccountApi.store.dispatch(hasError(false))
      AccountApi.store.dispatch(setUser({ isLoggedIn: true, account: response }))
    } catch (err) {
      console.error(err)
      AccountApi.store.dispatch(hasError(true))
      return err
    }
  }

  /**
   * It is used to logout a user
   * It removes token from cookies
   * It removes user from session storage
   */
  logout(): void {
    AccountApi.cookies.remove('token', { path: '/' })
    sessionStorage.removeItem('user')
    AccountApi.store.dispatch(accountsSuccess(null))
    AccountApi.store.dispatch(setUser({ isLoggedIn: false, account: null }))
    successToast('You have been logged out')
    window.location.href = '/login'
  }

  /**
   * Get account info
   * @param string @username -> Account name
   */
  async getAccountInfo(username: string) {
    try {
      const response = await this.request('POST', '/auth/account', { username })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all characters
   * belonging to an account
   */
  async getChars(username: string) {
    try {
      const response = await this.request('GET', `/char/getChars/${username}`)
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Validate the token
   * as a auth guard for routes
   */
  async validateToken() {
    try {
      const response = await this.request('GET', '/auth/validate')
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Confirm email token
   */
  async confirmEmail(token: string) {
    try {
      const response = await this.request('GET', `/auth/confirm/${token}`)
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Confirm reset email token
   */
  async resetPassword(token: string) {
    try {
      const response = await this.request('POST', `/auth/reset/${token}`)
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Send email to confirm email
   */
  async sendConfirmEmail(email: string, type: 'confirm' | 'reset' | 'change', username?: string) {
    try {
      const response = await this.request('POST', '/auth/email', {
        email,
        type,
        username,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Send email to confirm email
   */
  async sendResetEmail(email: string) {
    try {
      const response = await this.request('POST', '/auth/reset', {
        email,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Change password
   */
  async changePassword(username: string, password: string, newPassword: string) {
    try {
      const response = await this.request('POST', '/auth/changePassword', {
        username,
        password,
        newPassword,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Reset Password
   */
  async resetPasswordRequest(password: string, email: string, token: string) {
    try {
      const response = await this.request('POST', '/auth/resetPassword', {
        password,
        email,
        token,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * It gets all users from database
   */
  async getAllAccounts() {
    try {
      const request = await this.request('GET', '/auth/users', {})
      return request
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Changes the email in db
   */
  async changeEmail(username: string, email: string) {
    try {
      const request = await this.request('POST', '/auth/changeEmail', { email, username })
      return request
    } catch (err) {
      console.error(err)
    }
  }
}
