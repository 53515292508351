import './Dashboard.scss'
import { useSelector } from 'react-redux'
import { SpinnerComponent } from '../../components/Spinner/Spinner'
import Tabs from '../../components/Tabs/Tabs'
import AccountPanel from '../../components/AccountPanel/AccountPanel'

const Dashboard = () => {
  const data = useSelector((state: any) => state.user)

  const tabs = ['Account Details']
  const content = [<AccountPanel key='1' />]

  if (!data) {
    return <SpinnerComponent />
  }

  return (
    <main className='dashboard'>
      <div className='dashboard__container'>
        <Tabs tabs={tabs} content={content} />
      </div>
    </main>
  )
}

export default Dashboard
