import './ToS.scss'

const ToS = () => {
  return (
    <div className='tos'>
      <div className='tos__container'>
        <h2>Terms of Service for Cabal Chronicles</h2>

        <h3> 1. Registration</h3>
        <p>
          {' '}
          Cabal Chronicles is a free-to-play private server for the game Cabal Online. During the
          registration process, you will be asked for your email address and login credentials. If
          you provide an invalid email address, you will not be able to recover your password or
          change it if you have forgotten it. By registering, you accept our terms of service and
          privacy policy.
        </p>

        <h3>2. Account Information</h3>
        <p>
          {' '}
          You are allowed to share your account, but we are not responsible for any issues that may
          arise from doing so, such as scams or bans. You are not allowed to use multiple accounts
          to collect daily missions, abuse bugs, exchange or sell accounts or items for any
          currency, or participate in voting. We are not responsible for your account&apos;s
          security or for cases of hacked accounts. Internet cafes are considered one person,
          meaning that if one person gets banned, all users who logged in through the same IP will
          be affected as well.
        </p>

        <h3>3. Character Information</h3>
        <p>
          {' '}
          Our staff is not responsible for any scamming cases that occur in-game. It is your
          responsibility to ensure your own safety when trading with others. Inappropriate character
          names will be renamed.
        </p>

        <h3>4. Staff-Related Behaviour</h3>
        <p>
          {' '}
          Staff members are working for you and should be treated with respect. Impersonating a
          staff member will result in a warning and subsequent offenses will result in a permanent
          ban. Custom tags are not staff tags, only the legitimate tags listed in the TOS are
          considered as staff tags. You must be respectful towards all staff members, even if you do
          not agree with them. Repetitive disrespectful behavior towards them may result in a
          temporary ban and can ultimately lead to a permanent ban.
        </p>

        <h3>5. Use of Third-Party Programs and Bug Exploits</h3>
        <p>
          {' '}
          Using third-party programs and cheats is strictly prohibited and will result in a
          permanent ban. Exploiting bugs is also prohibited and will result in appropriate actions
          being taken, up to and including a permanent ban.
        </p>

        <h3>6. Donations</h3>

        <p>
          {' '}
          We do not issue refunds for donations made to the server. Donations are used to maintain
          and improve the server and its services.
        </p>

        <h3>7. Privacy</h3>

        <p>
          We do not sell or share any user information with third parties. We comply with GDPR
          guidelines for data privacy and protection.
        </p>
        <h3>8. H3urchases</h3>
        <p>
          All purchases made on our website are non-refundable. By proceeding with a purchase on our
          website, you accept the &apos;CABAL CHRONICLES PURCHASE TERMS AND CONDITIONS.&apos; These
          conditions will be presented before any purchase can be made, and it is important that
          they are read and understood carefully.
        </p>
        <h3>9. Support</h3>
        <p>
          Support is provided through our Discord server or through our support ticket system. If
          you have any questions or issues, please feel free to reach out to us through these
          channels.
        </p>
        <h3>10. Website</h3>
        <p>
          Abuse of website accounts may result in account suspension. Specific prohibited actions
          include changing the in-game nation multiple times, relogging to avoid being killed in
          Nation War, and relogging to reset Battle Mode. Penalties for violation of these rules may
          range from temporary bans to permanent bans.
        </p>
        <h3>11. Nation War</h3>
        <p>
          It is prohibited to participate in more than one Territory War on the same account, relog
          to avoid being killed, or relog to reset Battle Mode during a Territory War. Violations of
          these rules may result in temporary or permanent bans.
        </p>
        <p>Please note that we reserve the right to modify these terms of service at any time.</p>
      </div>
    </div>
  )
}

export default ToS
