import { createSlice } from '@reduxjs/toolkit'

/**
 * Create a slice of the store
 * for the account data after login
 * @see https://redux-toolkit.js.org/api/createSlice
 */
const slice = createSlice({
  name: 'user',
  initialState: {
    account: {},
    characters: [],
    isAdmin: false,
    cash: 0,
    isLoggedIn: false,
  },
  reducers: {
    /**
     * @description Update the state with the new data
     * @param state
     * @param action
     * @returns updated state
     */
    setUser: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export default slice.reducer
export const { setUser } = slice.actions
