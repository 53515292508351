import { SpinnerComponent } from '../../components/Spinner/Spinner'
import { Modal } from 'react-bootstrap'
import './DownloadModal.scss'
import { useState } from 'react'
import googleDrive from '../../assets/images/google-drive.png'
import mega from '../../assets/images/mega.png'
import dld from '../../assets/images/dld.png'

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const DownloadModal = (props: ModalProps) => {
  const { show, handleClose } = props
  return (
    <Modal show={show} onHide={handleClose} className='dlm'>
      <Modal.Header closeButton className='dlm__header'>
        <Modal.Title className='dlm__title'>Download</Modal.Title>
      </Modal.Header>
      <Modal.Body className='dlm__body'>
        <div className='dlm__container'>
          <p className='dlm__container__desc'>
            Download the full game from the links below. The game is available for Windows only. We
            recommend using the Google Drive link and Windows 10. If you encountered any issues we
            also recommend using the Launcher Patch and disabling your AntiVirus.
          </p>
          <p className='dlm__container__patch'>
            Current Version: 0.0.1
            <span className='dlm__container__desc'>Last Release date: 17.01.2023 </span>
          </p>
          <div className='dlm__container__item'>
            <div className='dlm__container__item__icon'>
              <img src={googleDrive} alt='google-drive' />
            </div>
            <div className='dlm__container__item__text'>
              <p className='dlm__container__item__text__desc'>Download from Google Drive</p>
            </div>
            <button className='dlm__container__item__button'>Download</button>
          </div>

          <div className='dlm__container__item'>
            <div className='dlm__container__item__icon'>
              <img src={mega} alt='google-drive' />
            </div>
            <div className='dlm__container__item__text'>
              <p className='dlm__container__item__text__desc'>Download from Mega</p>
            </div>
            <button className='dlm__container__item__button'>Download</button>
          </div>

          <div className='dlm__container__item'>
            <div className='dlm__container__item__icon'>
              <img src={dld} alt='google-drive' />
            </div>
            <div className='dlm__container__item__text'>
              <p className='dlm__container__item__text__desc'>Download the Launcher Patch</p>
            </div>
            <button className='dlm__container__item__button'>Download</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DownloadModal
