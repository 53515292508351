export const passwordConfig = [
  {
    name: 'oldPassword',
    label: 'Old Password',
    type: 'password',
    placeholder: 'Enter your old password',
    validation: {
      required: true,
      minLength: 8,
      maxLength: 20,
    },
  },
  {
    name: 'newPassword',
    label: 'New Password',
    type: 'password',
    placeholder: 'Enter your new password',
    validation: {
      required: true,
      minLength: 8,
      maxLength: 20,
    },
  },
  {
    name: 'confirmPassword',
    label: 'Confirm Password',
    type: 'password',
    placeholder: 'Confirm your new password',
    validation: {
      required: true,
      minLength: 8,
      maxLength: 20,
    },
  },
]
