import './ToS.scss'

const Privacy = () => {
  return (
    <div className='tos'>
      <div className='tos__container'>
        <h2>Privacy Policy for Cabal Chronicles</h2>
        <p>
          {' '}
          At Cabal Chronicles, we take your privacy seriously and are committed to protecting the
          personally identifiable information you may provide us through our website. This Privacy
          Policy explains what information we may collect on our website, how we use it, and under
          what circumstances we may disclose it to third parties.
        </p>
        <p>
          This policy applies solely to information collected through our website and does not apply
          to information collected through other sources. By using our website, you agree to our
          Privacy Policy and Terms of Service.
        </p>
        <p>
          We may collect non-personally identifying information such as browser type, language
          preference, and the date and time of each visitor request to understand how our visitors
          use our website. We may also collect potentially personally identifying information like
          IP addresses for logged in users and for users leaving comments on our blog. We only
          disclose logged in user and commenter IP addresses under the same circumstances that we
          use and disclose personally identifying information as described in this policy.
        </p>
        <p>
          When certain visitors interact with our website, we may collect personally identifying
          information such as a username and email address. We use commercially acceptable means to
          protect your personal information, but we cannot guarantee its absolute security.
        </p>
        <p>
          Our website may contain advertisements delivered by advertising partners, who may set
          cookies. These cookies allow the ad server to recognize your computer each time they send
          you an online advertisement. This information allows ad networks to deliver targeted
          advertisements that they believe will be of most interest to you. Our Privacy Policy
          covers the use of cookies by Cabal Chronicles and does not cover the use of cookies by any
          advertisers.
        </p>{' '}
        <p>
          {' '}
          Our Service may contain links to external sites that are not operated by us. If you click
          on a third party link, you will be directed to that third party&apos;s site. We strongly
          advise you to review the Privacy Policy and terms and conditions of every site you visit.
        </p>
        <p>
          We have no control over, and assume no responsibility for the content, privacy policies or
          practices of any third party sites, products or services.
        </p>
        <h3>Protection of Certain Personally-Identifying Information</h3>
        <p>
          Cabal Chronicles discloses potentially personally-identifying and personally-identifying
          information only to those of its employees, contractors and affiliated organizations that
          (i) need to know that information in order to process it on Cabal Chronicles&apos;s behalf
          or to provide services available at Cabal Chronicles&apos;s website, and (ii) that have
          agreed not to disclose it to others. Some of those employees, contractors and affiliated
          organizations may be located outside of your home country; by using Cabal
          Chronicles&apos;s website, you consent to the transfer of such information to them. Cabal
          Chronicles will not rent or sell potentially personally-identifying and
          personally-identifying information to anyone. Other than to its employees, contractors and
          affiliated organizations, as described above, Cabal One discloses potentially
          personally-identifying and personally-identifying information only in response to a
          subpoena, court order or other governmental request, or when Cabal One believes in good
          faith that disclosure is reasonably necessary to protect the property or rights of Cabal
          Chronicles, third parties or the public at large.
        </p>
        <p>
          If you are a registered user of https://cabalchronicles.com and have supplied your email
          address, Cabal Chronicles may occasionally send you an email to tell you about new
          features, solicit your feedback or just keep you up to date with what&apos;s going on with
          Cabal Chronicles and our products. We primarily use our blog to communicate this type of
          information, so we expect to keep this type of email to a minimum. If you send us a
          request (for example via a support email or via one of our feedback mechanisms), we
          reserve the right to publish it in order to help us clarify or respond to your request or
          to help us support other users. Cabal Chronicles takes all measures reasonably necessary
          to protect against the unauthorized access, use, alteration or destruction of potentially
          personally-identifying and personally-identifying information.
        </p>
        <h3> Aggregated Statistics</h3>
        <p>
          Cabal Chronicles may collect statistics about the behavior of visitors to its website.
          Cabal Chronicles may display this information publicly or provide it to others. However,
          Cabal Chronicles does not disclose your personally-identifying information.
        </p>
        <h3>Cookies</h3>
        <p>
          To enrich and perfect your online experience, Cabal Chronicles uses &quot;Cookies&quot;,
          similar technologies and services provided by others to display personalized content,
          appropriate advertising and store your preferences on your computer.
        </p>
        <p>
          A cookie is a string of information that a website stores on a visitor&apos;s computer,
          and that the visitor&apos;s browser provides to the website each time the visitor returns.
          Cabal Chronicles uses cookies to help Cabal Chronicles identify and track visitors, their
          usage of https://cabalchronicles.com, and their website access preferences. Cabal
          Chronicles visitors who do not wish to have cookies placed on their computers should set
          their browsers to refuse cookies before using Cabal Chronicles&apos;s websites, with the
          drawback that certain features of Cabal Chronicles&apos;s websites may not function
          properly without the aid of cookies.
        </p>
        <p>
          By continuing to navigate our website without changing your cookie settings, you hereby
          acknowledge and agree to Cabal Chronicles&apos;s use of cookies.
        </p>
        <h3>Privacy Policy Changes</h3>
        <p>
          Although most changes are likely to be minor, Cabal Chronicles may change its Privacy
          Policy from time to time, and in Cabal Chronicles&apos;s sole discretion. Cabal Chronicles
          encourages visitors to frequently check this page for any changes to its Privacy Policy.
          Your continued use of this site after any change in this Privacy Policy will constitute
          your acceptance of such change.
        </p>
        <h3>Contact Information</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us via email.
        </p>{' '}
      </div>
    </div>
  )
}

export default Privacy
