import { useEffect, useState } from 'react'
import './ResetPassword.scss'
import api from '../../api/instances/createConnections'
import { useNavigate, useParams } from 'react-router-dom'
import { pwdValidate } from '../../utils/validations/validations'
import { successToast } from '../../components/ToastContainer/ToastContainer'
import { ContentLoader } from '../../components/ContentLoader/ContentLoader'

const ResetPassword = () => {
  const params = useParams()
  const navigate = useNavigate()
  const token = params.token

  const [error, setError] = useState<any>(null)
  const [success, setSuccess] = useState<any>(null)
  const [email, setEmail] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const resetPasswordTokenVerification = async () => {
      const response = await api.client.resetPassword(token as string)
      if (response.error) {
        setError(response.error)
        return
      }
      setEmail(response.email)
    }
    resetPasswordTokenVerification()
  }, [])

  const changePassword = async (e: any) => {
    e.preventDefault()
    const newPassword = e.target[0].value
    const confirmPassword = e.target[1].value

    if (!pwdValidate(newPassword)) {
      setError('Password must be at least 8 characters long')
      return
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match')
      return
    }

    try {
      setLoading(true)
      const response = await api.client.resetPasswordRequest(newPassword, email, token as string)
      if (response.error) {
        setError(response.error)
        return
      }
      setSuccess(response.success)
      successToast('Password changed successfully')
      setTimeout(() => {
        navigate('/login')
      }, 5000)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const newPasswordForm = (
    <form className='reset-password__form' onSubmit={changePassword}>
      {error && <p className='reset-password__form--error'>{error}</p>}
      {success && <p className='reset-password__form--success'>{success}</p>}
      <input type='password' placeholder='New Password' className='reset-password__input' />
      <input type='password' placeholder='Confirm New Password' className='reset-password__input' />
      <button
        type='submit'
        className={loading ? 'reset-password__button--disabled' : 'reset-password__button'}
      >
        {loading ? <ContentLoader theme='light' size='large' /> : 'Submit'}
      </button>
    </form>
  )

  return (
    <div className='reset-password'>
      <h3>Type in your new password</h3>

      {newPasswordForm}
    </div>
  )
}

export default ResetPassword
