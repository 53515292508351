import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    isLoading: false,
    hasError: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    hasError: (state, action) => {
      state.hasError = action.payload
      state.isLoading = false
    },
    accountsSuccess: (state, action) => {
      state.users = action.payload
      state.isLoading = false
    },
  },
})

export default slice.reducer
export const { accountsSuccess, startLoading, hasError } = slice.actions
