import { createSlice } from '@reduxjs/toolkit'

/**
 * Create a slice of the store
 * for the news in database
 * @see https://redux-toolkit.js.org/api/createSlice
 */

const slice = createSlice({
  name: 'news',
  initialState: {
    news: [],
    hasData: false,
  },
  reducers: {
    setNews: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export default slice.reducer
export const { setNews } = slice.actions
