import { Navigate } from 'react-router-dom'
import { useLoggedIn } from '../../hooks/useLoggedIn'
import { SpinnerComponent } from '../../components/Spinner/Spinner'
import { useSelector } from 'react-redux'

/**
 *
 * @param param0 children
 * @returns the children if the user is logged in, otherwise redirects to login
 * @example
 * <AuthGuard>
 *  <Dashboard />
 * </AuthGuard>
 *
 */
export const AuthGuard = ({ children }: any) => {
  const isLoggedInState = useSelector((state: any) => state.user.isLoggedIn)
  const isLoggedIn = useLoggedIn()

  if (isLoggedInState) {
    return children
  } else if (isLoggedIn === undefined) {
    return <SpinnerComponent />
  }

  return isLoggedIn ? children : <Navigate to='/login' />
}
