import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import api from '../../api/instances/createConnections'
import { serverTime } from '../../utils/constants/constants'
import DownloadModal from '../DownloadModal/DownloadModal'
import VoteModal from '../VoteModal/VoteModal'
import DonateModal from '../DonateModal/DonateModal'
import logoWhite from '../../assets/images/logo-white.png'
import './NavBar.scss'

const NavBar = () => {
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn)
  const [showFocused, setShowFocused] = useState(false)
  const [navbar, setNavbar] = useState(false)
  const [showDlm, setShowDlm] = useState<boolean>(false)
  const [showVote, setShowVote] = useState<boolean>(false)
  const [showDonate, setShowDonate] = useState<boolean>(false)
  const handleCloseDonate = () => setShowDonate(false)
  const handleCloseVote = () => setShowVote(false)
  const handleCloseDlm = () => setShowDlm(false)

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener('scroll', changeBackground)
  }, [])

  const handleSetFocused = () => {
    setShowFocused(true)
  }

  const handleRemoveFocused = () => {
    setShowFocused(false)
  }

  return (
    <nav className={navbar ? 'navigation active' : 'navigation'} onScroll={changeBackground}>
      <div
        className='navigation__container'
        onMouseEnter={handleSetFocused}
        onMouseLeave={handleRemoveFocused}
      >
        <div className='navigation__logo'>
          <a href='/'>
            <img src={logoWhite} alt='Cabal Chronicles Logo' loading='lazy' />
          </a>
          {showFocused && (
            <div className='navigation__server-time'>
              <div className='navigation__server-time__group'>
                <p className='navigation__server-time__title'>Server Time</p>
                <p className='navigation__server-time__time'>{serverTime}</p>
              </div>
              {/* <div className='navigation__server-time__group'>
                <p className='navigation__server-time__title'>Mission War</p>
                <p className='navigation__server-time__time'>{missionWar}</p>
              </div> */}
            </div>
          )}
        </div>
        <div className='navigation__links'>
          <div className='navigation__links__group'>
            <Link to='/register' className='navigation__links__header__btn'>
              Register
            </Link>
            {showFocused && (
              <div className='navigation__links__group--focus'>
                <p className='navigation__links__btn' onClick={() => setShowDlm(true)}>
                  Download
                </p>
              </div>
            )}
          </div>
          <div className='navigation__links__group'>
            <Link className='navigation__links__header' to='/about'>
              Server Information
            </Link>
            {showFocused && (
              <div className='navigation__links__group--focus'>
                <Link to='/about'>About</Link>
                <Link to='/rankings'>Rankings</Link>
                {/* <div className='navigation__links__group--focus__war'>
                  <Link to='/warstatus'>War Status</Link>
                  <p className='war-timer'>{timer}</p>
                </div> */}
                <Link to='/'>News</Link>
                <p className='navigation__links__group__link' onClick={() => setShowVote(true)}>
                  Vote
                </p>
              </div>
            )}
          </div>
          <div className='navigation__links__group'>
            <Link className='navigation__links__header' to='/'>
              Community
            </Link>
            {showFocused && (
              <div className='navigation__links__group--focus'>
                <a href='https://discord.gg/BaKvVusqvE' target='_blank' rel='noreferrer'>
                  Discord
                </a>
                <a
                  href='https://www.facebook.com/groups/711870753625992'
                  target='_blank'
                  rel='noreferrer'
                >
                  Facebook
                </a>
                <Link to='/'>Twitch</Link>
                <Link to='/'>Youtube</Link>
              </div>
            )}
          </div>
          <div className='navigation__links__group'>
            <Link className='navigation__links__header' to='/dashboard'>
              Profile
            </Link>
            {showFocused && (
              <div className='navigation__links__group--focus'>
                <Link to='/dashboard'>Dashboard</Link>
                <p className='navigation__links__group__link' onClick={() => setShowDonate(true)}>
                  Donate
                </p>
                {isLoggedIn && (
                  <a
                    className='navigation__links__group--focus__logout'
                    onClick={api.client.logout}
                  >
                    Logout
                  </a>
                )}
                {!isLoggedIn && <Link to='/login'>Login</Link>}
                {!isLoggedIn && <Link to='/register'>Register</Link>}
              </div>
            )}
          </div>
        </div>
      </div>
      {showFocused && <div className='navigation__focused'></div>}
      <DownloadModal show={showDlm} handleClose={handleCloseDlm} />
      {isLoggedIn && (
        <>
          <VoteModal show={showVote} handleClose={handleCloseVote} />
          <DonateModal show={showDonate} handleClose={handleCloseDonate} />
        </>
      )}
    </nav>
  )
}

export default NavBar
