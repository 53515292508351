import { useSelector } from 'react-redux'
import { Account } from '../../types/interfaces'
import { SpinnerComponent } from '../Spinner/Spinner'
import { formatDate } from '../../utils/constants/constants'
import './AccountPanel.scss'
import { useEffect, useState } from 'react'
import { ModalGenerator } from '../ModalGenerator/ModalGenerator'
import { passwordConfig } from '../../pages/Dashboard/configs/changePassword.config'
import { emailConfig } from '../../pages/Dashboard/configs/changeEmail.config'
import { ContentLoader } from '../ContentLoader/ContentLoader'
import api from '../../api/instances/createConnections'
import { errorToast, successToast } from '../ToastContainer/ToastContainer'
import { pwdValidate } from '../../utils/validations/validations'
import VoteModal from '../VoteModal/VoteModal'
import DonateModal from '../DonateModal/DonateModal'

const AccountPanel = () => {
  /**
   * Get account data from redux
   */
  const accountData = useSelector((state: any) => state.user.account)
  const accountCash = useSelector((state: any) => state.user.cash)

  /**
   * Set state for account data
   */
  const [userData, setUserData] = useState({} as Account)
  const [selectedEdit, setSelectedEdit] = useState<string>('')
  const [cash, setCash] = useState<any>(null)
  const [show, setShow] = useState(false)
  const [config, setConfig] = useState({} as any)
  const [showVoteModal, setShowVoteModal] = useState(false)
  const [showDonateModal, setShowDonateModal] = useState(false)
  const handleCloseVoteModal = () => setShowVoteModal(false)
  const handleCloseDonateModal = () => setShowDonateModal(false)

  /**
   * @description Navigate to vote page
   */
  const handleOpenVoteModal = () => {
    setShowVoteModal(true)
  }

  const formSubmit = async (data: any) => {
    if (selectedEdit === 'password') {
      if (pwdValidate(data.newPassword) === false) {
        errorToast('Password must be stronger')
        return
      }

      if (data.newPassword !== data.confirmPassword) {
        errorToast('Passwords do not match!')
        return
      }

      try {
        const response = await api.client.changePassword(
          userData.ID,
          data.oldPassword,
          data.newPassword,
        )
        if (response.error) {
          errorToast(response.error)
          return
        }
        await api.client.logout()
        successToast('Password changed successfully!')
        handleClose()
      } catch (error) {
        console.error(error)
        errorToast('Something went wrong!')
      }
    }

    if (selectedEdit === 'email') {
      if (data.email !== data.confirmEmail) {
        errorToast('Emails do not match!')
        return
      }

      try {
        const response = await api.client.sendConfirmEmail(data.email, 'change', userData.ID)
        if (response.error) {
          errorToast(response.error)
          return
        }
        successToast('Email change requested. Please check your email!')
        handleClose()
      } catch (error) {
        console.error(error)
        errorToast('Something went wrong!')
      }
    }
  }

  /**
   * @description Close modal
   * @returns null
   */
  const handleClose = () => setShow(false)

  /**
   * @description Handle edit button click for each field
   * @param edit
   * @returns void
   */
  const handleEdit = (edit: string) => () => {
    if (edit === 'password') {
      setConfig(passwordConfig)
      setSelectedEdit('password')
    }
    if (edit === 'email') {
      setConfig(emailConfig)
      setSelectedEdit('email')
    }
    setShow(true)
  }

  /**
   * @description Verify email address
   */
  const verifyEmail = async () => {
    const response = await api.client.sendConfirmEmail(userData.Email, 'confirm')
    if (response.error) {
      errorToast('Something went wrong!')
      return
    }
    successToast('Email verification sent!')
  }

  /**
   * @description Set account data to state
   * if account data is not null every time the component renders
   * and the data updates
   */
  useEffect(() => {
    if (accountData) {
      setUserData(accountData.user)
    }
    if (accountCash) {
      if (accountCash.error) {
        setCash('0')
      }
      setCash(String(accountCash?.cash?.Cash))
    }
  }, [accountData, accountCash])

  /**
   * If account data is not loaded, show spinner
   */
  if (!userData.ID) {
    return <SpinnerComponent />
  }

  return (
    <div className='account-panel'>
      <div className='account-panel__container'>
        <div className='account-panel__content'>
          <div className='account-panel__content__item'>
            <p className='account-panel__content__item--key'>Username:</p>
            <p className='account-panel__content__item--value'>{userData.ID}</p>
          </div>
          <div className='account-panel__content__item'>
            <p className='account-panel__content__item--key'>Email:</p>
            <p className='account-panel__content__item--value'>
              {userData.Email} <span onClick={handleEdit('email')}>EDIT</span>
            </p>
          </div>
          <div className='account-panel__content__item'>
            <p className='account-panel__content__item--key'>Password:</p>
            <p className='account-panel__content__item--value'>
              ******** <span onClick={handleEdit('password')}>EDIT</span>
            </p>
          </div>
          {/* <div className='account-panel__content__item'>
            <p className='account-panel__content__item--key'>Sub Password:</p>
            <p className='account-panel__content__item--value'>
              ******** <span onClick={handleEdit('subpassword')}>EDIT</span>
            </p>
          </div>
          <div className='account-panel__content__item'>
            <p className='account-panel__content__item--key'>Secret Question:</p>
            <p className='account-panel__content__item--value'>
              {questionTypes(userData.Question)} <span onClick={handleEdit('question')}>EDIT</span>
            </p>
          </div> */}
          <div className='account-panel__content__item'>
            <p className='account-panel__content__item--key'>Created on:</p>
            <p className='account-panel__content__item--value'>{formatDate(userData.createDate)}</p>
          </div>
          {/* <div className='account-panel__content__footer'>
            <a
              href='https://discord.gg/BaKvVusqvE'
              target='_blank'
              rel='noreferrer'
              className='account-panel__content__footer--text'
            >
              LEARN HOW TO GET FREE ECOINS{' '}
            </a>
            <img
              src={userCPimg}
              alt='usercp-faq-submenu-title'
              className='account-panel__content__footer--img'
            />
            <img
              src={bhaloo}
              alt='usercp-faq-bhaloo'
              className='account-panel__content__footer--bhaloo'
            />
          </div> */}
          <section className='account-panel__aside__mailVerified'>
            <p className='account-panel__aside__mailVerified__text'>
              Your email is:
              <span
                className={
                  userData.Verified
                    ? 'account-panel__aside__mailVerified__text--verified'
                    : 'account-panel__aside__mailVerified__text--notVerified'
                }
              >
                {userData.Verified ? 'Verified' : 'Not Verified'}
              </span>
              {!userData.Verified ? (
                <button
                  className='account-panel__aside__mailVerified__text--button'
                  onClick={verifyEmail}
                >
                  Verify your email
                </button>
              ) : (
                <button
                  className='account-panel__aside__mailVerified__text--button'
                  onClick={handleOpenVoteModal}
                >
                  Vote for Coins
                </button>
              )}
            </p>
          </section>
        </div>
        <aside className='account-panel__aside'>
          <div className='account-panel__aside__item'>
            <p className='account-panel__aside__item--key'>My eCoin Balance:</p>
            <div className='account-panel__aside__item--value'>
              {cash ? cash : <ContentLoader theme='dark' size='large' />}
            </div>
          </div>
          <button
            className='account-panel__aside__item--button'
            onClick={() => setShowDonateModal(true)}
          >
            ADD BALANCE
          </button>
          <div className='account-panel__aside__help'>
            <p className='account-panel__aside__help__title'> Need help ?</p>

            <a
              href='https://discord.gg/BaKvVusqvE'
              target='_blank'
              rel='noreferrer'
              className='account-panel__aside__help'
            >
              Contact Us or create a Support ticket on our discord
            </a>
          </div>
        </aside>
        <ModalGenerator
          handleShow={show}
          title='Update Account Info'
          handleClose={handleClose}
          configs={config}
          onSubmit={formSubmit}
          fullscreen={'lg-down'}
        />
      </div>
      <VoteModal show={showVoteModal} handleClose={handleCloseVoteModal} />
      <DonateModal show={showDonateModal} handleClose={handleCloseDonateModal} />
    </div>
  )
}

export default AccountPanel
