import { PaypalLog } from '../../components/DonateModal/DonateModal'
import { Rest } from '../baseApiRest/rest'

export class CashApi extends Rest {
  constructor(baseUrl: string) {
    super(baseUrl)
  }

  /**
   * Get Cash info
   * @param string $account Account name
   */

  getCashParadox = async (account: string) => {
    try {
      const response = await this.request('GET', `/cash/get/${account}`)
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * update Cash for user
   */
  updateCash = async (account: string, cash: number) => {
    try {
      const response = await this.request('POST', '/cash/update/', {
        username: account,
        cash: cash,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * create new Paypal LOG
   */
  createPaypalLog = async (data: PaypalLog) => {
    try {
      const response = await this.request('POST', '/cash/updatePaypalLog', {
        data,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Vote for ecoins
   */
  voteEcoins = async (account: string, siteID: number) => {
    try {
      const response = await this.request('POST', '/auth/vote', {
        siteID: siteID,
        username: account,
      })
      return response
    } catch (err) {
      console.error(err)
    }
  }
}
