import { Modal } from 'react-bootstrap'
import { FormGenerator } from '../FormGenerator/FormGenerator'
import './ModalGenerator.scss'

interface FormGeneratorInterfaceConfig {
  label: string
  type: string
  name: string
  value?: string
  placeholder?: string
  validation?: any
}

interface Props {
  handleShow: boolean | undefined
  title: JSX.Element | string
  handleClose: () => void
  contents?: JSX.Element
  fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'
  configs?: FormGeneratorInterfaceConfig[]
  footer?: JSX.Element
  onSubmit: (data: any) => void
}

export const ModalGenerator = ({
  fullscreen,
  contents,
  handleShow,
  title,
  handleClose,
  footer,
  configs,
  onSubmit,
}: Props) => {
  return (
    <>
      <Modal show={handleShow} onHide={handleClose} fullscreen={fullscreen}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          {contents && <div className='modal-contents'>{contents}</div>}
          {configs && <FormGenerator configs={configs} onSubmit={onSubmit} />}
        </Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    </>
  )
}
