import Cookies from 'universal-cookie'
import { store } from '../../store/DataLayer'

/**
 * It is a base class for all api classes
 * It is used to make requests to the server
 */
export class Rest {
  public static baseUrl: string
  public static store: any
  public static token: string
  public static options: {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
    headers: {
      'Content-Type': string
      authorization: string
      Origin: string
    }
    body?: any
  }
  public static cookies: Cookies

  constructor(baseUrl: string) {
    Rest.baseUrl = baseUrl
    Rest.cookies = new Cookies()
    Rest.store = store
    Rest.options = {
      headers: {
        'Content-Type': 'application/json',
        authorization: Rest.token,
        Origin: 'https://cabalchronicles.com',
      },
    }
  }

  /**
   *
   * @param method
   * @param url
   * @param body
   * @returns Promise<any>
   *
   * It makes a request to the server
   * It returns a promise
   * Can be used in a generic way as long
   * as the params and url are passed
   */
  async request(method: string, url: string, body: any = {}) {
    Rest.token = Rest.cookies.get('token')
    Rest.options.headers.authorization = Rest.token

    Rest.options.method = method as typeof Rest.options.method
    if (Rest.options.method === 'GET') {
      delete Rest.options.body
    }

    if (Rest.options.method !== 'GET') {
      Rest.options.body = JSON.stringify(body)
    }

    try {
      const response = await fetch(`${Rest.baseUrl}${url}`, Rest.options)
      const data = await response.json()
      if (data.message?.errors) {
        return data.message.errors
      }
      return data
    } catch (error) {
      console.error(error)
      return error
    }
  }
}
