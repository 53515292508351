import './PageNotFound.scss'
import bhaloo from '../../assets/images/bhaloo.png'

const PageNotFound = () => {
  return (
    <main className='page-not-found'>
      <section className='page-not-found__container'>
        <h1 className='page-not-found__title'>404</h1>
        <h2 className='page-not-found__subtitle'>Page not found</h2>
        <img className='page-not-found__image' src={bhaloo} alt='Bhaloo' />
      </section>
    </main>
  )
}

export default PageNotFound
