import { createSlice } from '@reduxjs/toolkit'

/**
 * Create a slice of the store
 * for the server data
 * @see https://redux-toolkit.js.org/api/createSlice
 */
const slice = createSlice({
  name: 'data',
  initialState: {
    onlinePlayers: 0,
    FCO: [],
    capellaPlayersTotal: 0,
    capellaPlayersOnline: 0,
    procyonPlayersTotal: 0,
    procyonPlayersOnline: 0,
    allCharacters: [],
    neutralPlayersTotal: 0,
    neutralPlayersOnline: 0,
    topCharacter: [],
    capellaBringer: [],
    procyonBringer: [],
    top10Online: [],
    allGuilds: [],
    top10Reputation: [],
    top10Level: [],
    top10Alz: [],
    allAccounts: [],
    hasData: false,
  },
  reducers: {
    /**
     * @description Update the state with the new data
     * @param state
     * @param action
     * @returns updated state
     */
    setData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export default slice.reducer
export const { setData } = slice.actions
