import { NewsApi } from '../news/news'
import { AccountApi } from '../clientApi/clientApi'
import { CashApi } from '../cashApi/cashApi'
import { CharacterApi } from '../characterApi/characterApi'

/**
 * URL & PORT are changed on production through the .env file
 */
const DB_URL = (process.env.REACT_APP_DB_HOST as string) || 'https://server.cabalchronicles.com'
const DB_PORT = (process.env.REACT_APP_DB_PORT as string) || '443'
// const DB_URL = 'http://localhost'
// const DB_PORT = '5001'
const URI = `${DB_URL}:${DB_PORT}`

/**
 * @description This is the main API instance that is used to call the API
 */
export const news = new NewsApi(URI)
export const client = new AccountApi(URI)
export const cash = new CashApi(URI)
export const characters = new CharacterApi(URI)

/**
 * Export the API instance
 * as a single instanced object
 */
const api = {
  news,
  client,
  cash,
  characters,
}

export default api
