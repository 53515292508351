import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import users from './user/actions/getAccounts'
import user from './user/user'
import server from './server/server'
import news from './server/news'

import { Provider } from 'react-redux'
import { FC } from 'react'

type Props = {
  children: React.ReactNode
}

const reducer = combineReducers({
  users,
  user,
  server,
  news,
})

export const store = configureStore({
  reducer,
})

const DataLayerComponent: FC<Props> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}

export default DataLayerComponent
