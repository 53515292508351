import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api/instances/createConnections'
import { SpinnerComponent } from '../../components/Spinner/Spinner'
import './LoginPage.scss'
import { useSelector } from 'react-redux'
import { errorToast, successToast } from '../../components/ToastContainer/ToastContainer'
import bg9 from '../../assets/video/bg9.mp4'

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const isLoading = useSelector(
    (state: {
      users: {
        isLoading: boolean
      }
    }) => state.users.isLoading,
  )

  const loggedIn = useSelector(
    (state: {
      user: {
        isLoggedIn: boolean
      }
    }) => state.user.isLoggedIn,
  )

  useEffect(() => {
    if (loggedIn) {
      navigate('/')
    }
  }, [loggedIn, navigate])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    /**
     * Fetch data from API
     * with username and password
     */
    const response = await api.client.login(username, password)
    if (response?.error) {
      setError(response.error)
      errorToast('Login Failed')
      return false
    } else {
      setError(null)
      successToast('Login Successfully')
      navigate('/dashboard')
    }
  }

  if (isLoading) {
    return <SpinnerComponent />
  }

  return (
    <div className='login-page'>
      <section className='login-page__container'>
        <h1 className='login-page__title'>Login Account</h1>
        <form className='login-page__form' onSubmit={handleSubmit}>
          <div className='login-page__group'>
            <input
              className='login-page__input'
              placeholder='Username'
              type='text'
              name='username'
              id='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='login-page__group'>
            <input
              className='login-page__input'
              placeholder='Password'
              type='password'
              name='password'
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className='login-page__button' type='submit'>
            Login
          </button>
          <Link to={'/register'} className='login-page__register-btn'>
            Don&apos;t have an account? Register here !
          </Link>
          <Link to={'/forgot-password'} className='login-page__forgot-password'>
            Forgot password?
          </Link>
        </form>
        <p className='login-page__error'>{error}</p>
      </section>
      <video className='login-page__video' autoPlay loop muted>
        <source src={bg9} type='video/mp4' />
      </video>
    </div>
  )
}

export default LoginPage
