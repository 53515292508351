import './HomePage.scss'
import { useSelector } from 'react-redux'
import rise from '../../assets/images/2.jpg'
import discount from '../../assets/images/1.jpg'
import { ContainerLoading } from '../../components/ContainerLoading/ContainerLoading'
import { formatDate } from '../../utils/constants/constants'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import DownloadModal from '../../components/DownloadModal/DownloadModal'
import Button from '../../components/Utils/Button'
import VideoBG from '../../components/Utils/Video'

const HomePage = () => {
  const news = useSelector((state: any) => state.news)
  const [latestNews, setLatestNews] = useState<any>()
  const [showDlm, setShowDlm] = useState(false)
  const handleCloseDml = () => setShowDlm(false)

  useEffect(() => {
    if (news.hasData) {
      const latestNews = news.news.news.slice(0, 5)
      setLatestNews(latestNews)
    }
  }, [news.hasData])

  return (
    <div className='homepage'>
      <div className='homepage__overlay'></div>
      <VideoBG />
      <section className='homepage__hero'>
        <div className='homepage__header'>
          <h1 className='homepage__title'>Replay favorite moments</h1>
          <Button text='Download' onClick={() => setShowDlm(true)} />
          <Button text='Discord' url='https://discord.gg/BaKvVusqvE' />
        </div>
        <div className='homepage__content'>
          <div className='homepage__content__card'>
            <div className='homepage__content__card__title'>
              <p>Announcements</p>
            </div>
            <div className='homepage__content__card__body'>
              <div className='homepage__content__card__body__news'>
                {!news.hasData && <ContainerLoading />}
                {latestNews &&
                  latestNews.map((article: any) => (
                    <Link
                      to={`/article/${article.id}`}
                      className='homepage__content__card__body__article'
                      key={article.id}
                    >
                      <p
                        className={`homepage__content__card__body__article__type ${article.nType}`}
                      >
                        {article.nType}
                      </p>
                      <p className='homepage__content__card__body__article__title'>
                        {article.title}
                      </p>
                      <p className='homepage__content__card__body__article__date'>
                        {formatDate(article.nDate)}
                      </p>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className='homepage__content__card'>
            <div className='homepage__content__card__title'>
              <p>Updates & Events</p>
            </div>
            <div className='homepage__content__card__body'>
              <Carousel
                autoPlay={true}
                showThumbs={false}
                showArrows={true}
                infiniteLoop={true}
                interval={3000}
                showIndicators={false}
                showStatus={false}
                dynamicHeight={true}
                className='homepage__content__card__body__carousel'
              >
                <div>
                  <Link to='article/1'>
                    <img src={rise} alt='rise' />
                  </Link>
                </div>
                <div>
                  <img src={discount} alt='discount' />
                </div>
              </Carousel>
            </div>
            .
          </div>
        </div>
        <DownloadModal show={showDlm} handleClose={handleCloseDml} />
      </section>
    </div>
  )
}

export default HomePage
