import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { AccountApi } from '../api/clientApi/clientApi'
import api from '../api/instances/createConnections'
import { setUser } from '../store/user/user'

const cookies = new Cookies()

/**
 *
 * @returns boolean
 * Custom hook to check if a user is logged in
 */
export const useLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>()
  const token = cookies.get('token')

  useEffect(() => {
    if (!token || token == undefined) {
      setLoggedIn(false)
      return
    }

    const checkLoggedIn = async (): Promise<void> => {
      try {
        const response = await api.client.validateToken()
        if (response.error) {
          setLoggedIn(false)
          return
        }
        setLoggedIn(true)
        AccountApi.store.dispatch(setUser({ isLoggedIn: true, account: response }))
      } catch (err) {
        console.error(err)
        setLoggedIn(false)
        return
      }
    }

    checkLoggedIn()
  }, [])

  return loggedIn
}
