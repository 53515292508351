const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID as string
import { Modal } from 'react-bootstrap'
import './DonateModal.scss'

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { useEffect, useState } from 'react'
import api from '../../api/instances/createConnections'
import { useSelector } from 'react-redux'
import {
  errorToast,
  successToast,
  warningToast,
} from '../../components/ToastContainer/ToastContainer'
import { useNavigate } from 'react-router-dom'
import { Account } from '../../types/interfaces'

/**
 * @description options for paypal payment
 */
const options = [
  { value: 'option1', label: '1,000 eCoins (5 USD) + 100 eCoins Bonus', cost: 5, ecoins: 1100 },
  { value: 'option2', label: '5,000 eCoins (20 USD) + 500 eCoins Bonus', cost: 20, ecoins: 5500 },
  {
    value: 'option3',
    label: '10,000 eCoins (35 USD) + 1000 eCoins Bonus',
    cost: 35,
    ecoins: 11000,
  },
  {
    value: 'option4',
    label: '20,000 eCoins (60 USD) + 2000 eCoins Bonus',
    cost: 60,
    ecoins: 22000,
  },
  {
    value: 'option5',
    label: '50,000 eCoins (120 USD) + 10000 eCoins Bonus',
    cost: 120,
    ecoins: 60000,
  },
]

interface ModalProps {
  show: boolean
  handleClose: () => void
}

interface Option {
  value: string
  label: string
  cost: number
  ecoins: number
}

export interface PaypalLog {
  orderid: string
  item: string
  username: string
  name: string
  lastname: string
  country: string
  payamount: string
  payerid: string
  email: string
  status: string
  street?: string
  state?: string
}

interface PayerInfo {
  address: {
    country_code: string
  }
  email_address: string
  name: {
    given_name: string
    surname: string
  }
  payer_id: string
}

const DonateModal = (props: ModalProps) => {
  const { show, handleClose } = props

  const [orderID, setOrderID] = useState<null | string>(null)
  const [success, setSuccess] = useState('success')
  const [payer, setPayer] = useState<PayerInfo | null>(null)
  const navigate = useNavigate()
  const [selectedPackage, setSelectedPackage] = useState<Option>(options[0])
  const account: Account = useSelector((state: any) => state.user.account.user)

  useEffect(() => {
    if ((!account?.Verified || account?.Verified == 0) && show) {
      warningToast('You need to verify your account to Donate')
      handleClose()
    }
  }, [show])
  const handlePackageChange = (e: any) => {
    switch (e.target.value) {
      case 'option1':
        setSelectedPackage(options[0])
        break
      case 'option2':
        setSelectedPackage(options[1])
        break
      case 'option3':
        setSelectedPackage(options[2])
        break
      case 'option4':
        setSelectedPackage(options[3])
        break
      case 'option5':
        setSelectedPackage(options[4])
        break
      default:
        setSelectedPackage(options[0])
    }
  }
  /**
   * @description creates the order for paypal payment
   * @param data
   * @param actions
   * @returns void
   */
  const createOrder = (data: any, actions: any) => {
    return actions.order
      .create({
        // eslint-disable-next-line camelcase
        purchase_units: [
          {
            description: selectedPackage.label,
            amount: {
              // eslint-disable-next-line camelcase
              currency_code: 'USD',
              value: selectedPackage.cost,
            },
          },
        ],
      })
      .then((orderId: string) => {
        setOrderID(orderId)
        return orderId
      })
  }

  /**
   * @description handles the approval of the paypal payment
   * @param data
   * @param actions
   * @returns void
   */
  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then(async function (details: any) {
      const { payer } = details
      setPayer(payer)
      try {
        const response = await api.cash.updateCash(account.ID, selectedPackage.ecoins)
        if (response.success) {
          successToast('Payment was successful')
          setSuccess('success')
          navigate('/dashboard')
        } else {
          errorToast('An Error occured with your payment ')
          setSuccess('failed')
        }
      } catch (error) {
        console.error(error)
      }
    })
  }
  /**
   * @description Creates a new log in the database for the paypal payment
   * when the payment is successful and the payer is not null
   */
  useEffect(() => {
    if (success === 'success' && payer) {
      createPaypalLog(payer)
    }
  }, [success, payer])

  /**
   * @description creates a new log in the database for the paypal payment
   */
  const createPaypalLog = async (payerInfo: PayerInfo) => {
    const paypalLog: PaypalLog = {
      orderid: orderID as string,
      item: selectedPackage.label,
      username: account.ID,
      name: payerInfo.name.given_name,
      lastname: payerInfo.name.surname,
      country: payerInfo.address.country_code,
      payamount: String(selectedPackage.cost),
      payerid: payerInfo.payer_id,
      email: payerInfo.email_address,
      status: success,
    }

    try {
      await api.cash.createPaypalLog(paypalLog)
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * @description handles errors for paypal payment
   * @param err
   */
  const onError = (err: any) => {
    console.error(err)
    errorToast('An Error occured with your payment ')
  }
  return (
    <Modal show={show} onHide={handleClose} className='donate'>
      <Modal.Header closeButton className='donate__header'>
        <Modal.Title className='donate__title'>Donate</Modal.Title>
      </Modal.Header>
      <Modal.Body className='donate__body'>
        <PayPalScriptProvider options={{ 'client-id': CLIENT_ID }}>
          <div className='donate__container'>
            <section className='donate__product-list'>
              <select
                onChange={handlePackageChange}
                className='donate__product-list__select'
                defaultValue={'option1'}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <p>PayPal has a guest donate for cards that does not require an account.</p>
              <PayPalButtons
                style={{ layout: 'vertical' }}
                forceReRender={[selectedPackage]}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
              />
            </section>
          </div>
        </PayPalScriptProvider>
      </Modal.Body>
    </Modal>
  )
}

export default DonateModal
