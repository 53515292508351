export const emailConfig = [
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Enter your password',
    validation: {
      required: true,
    },
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Enter your email',
    validation: {
      required: true,
    },
  },
  {
    name: 'confirmEmail',
    label: 'Confirm Email',
    type: 'email',
    placeholder: 'Confirm your email',
    validation: {
      required: true,
    },
  },
]
