import { warningToast } from '../../components/ToastContainer/ToastContainer'
import { setUser } from '../../store/user/user'
import api from './createConnections'

/**
 * @description This function is used to get the account data from the API and store it in the redux store
 * @param dispatch
 * @param username
 */
const accountStorePiece = async (dispatch: any, username: string) => {
  if (!username) {
    warningToast('No username provided')
  }
  const characters = await api.client.getChars(username)
  const cash = await api.cash.getCashParadox(username)

  dispatch(
    setUser({
      characters: characters,
      cash: cash,
    }),
  )
}

export default accountStorePiece
