import './ContainerLoading.scss'

export const ContainerLoading = () => {
  return (
    <div className='loader'>
      <div className='container-spinner'></div>
      <p className='loader__text'>Loading...</p>
    </div>
  )
}
