import { useSelector } from 'react-redux'
import { SpinnerComponent } from '../../components/Spinner/Spinner'
import './ArticlePage.scss'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Article } from '../../types/interfaces'
import { formatDate } from '../../utils/constants/constants'

const ArticlePage = () => {
  const news = useSelector((state: any) => state.news)
  const params = useParams()
  const newsID = params.id
  // const [show, setShow] = useState(false)
  // const handleClose = () => setShow(false)
  const [error, setError] = useState<boolean | string>(false)
  const [article, setArticle] = useState<Article>({
    title: '',
    author: '',
    nType: 'Announcement',
    body: '',
    nDate: '',
  })
  // const [newArticle, setNewArticle] = useState<Article>({
  //   title: '',
  //   author: '',
  //   nType: 'Announcement',
  //   body: '',
  //   nDate: '',
  // })

  useEffect(() => {
    if (news.hasData) {
      const article = news.news.news.find((article: any) => article.id == newsID)
      if (!article) {
        setError('Article not found')
        return
      }
      setArticle(article)
      setError(false)
    }
  }, [news.hasData])

  // const handleSubmit = () => {
  //   if (!newArticle.title || !newArticle.author || !newArticle.nType || !newArticle.body) {
  //     errorToast('Please fill all the fields')
  //     return
  //   }
  //   try {
  //     api.news.createArticle(newArticle.title, newArticle.author, newArticle.nType, newArticle.body)
  //     handleClose()
  //     successToast('rticle created successfully')
  //   } catch (error: any) {
  //     errorToast(error.message)
  //   }
  // }

  if (!news.hasData) {
    return <SpinnerComponent />
  }
  return (
    <div className='article-page'>
      <div className='article-page__container'>
        {/* {isAdmin && (
          <button className='article-page__container__button' onClick={() => setShow(true)}>
            Add Article
          </button>
        )} */}
        <div className='article-page__article'>
          {error && <h3 className='error'>{error}</h3>}
          {article && (
            <div className='article-page__article__content'>
              <div className='article-page__article__content__left'>
                <p className='article-page__article__content__title'>{article.title}</p>
                <p className={`article-page__article__content__type ${article.nType}`}>
                  Category: {article.nType}
                </p>
                <p className='article-page__article__content__author'>Author: {article.author}</p>
                <p className='article-page__article__content__date'>{formatDate(article.nDate)}</p>
              </div>
              <div className='article-page__article__content__vertical--separator'></div>
              <p
                className='article-page__article__content__body'
                dangerouslySetInnerHTML={{ __html: article.body }}
              ></p>
            </div>
          )}
        </div>
      </div>
      {/* <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new Article</Modal.Title>
        </Modal.Header>
        <Modal.Body className='article-control'>
          <div className='article-control__inputs'>
            <input
              className='article-control__input'
              type={'text'}
              placeholder='Article Title'
              onChange={(e) => setNewArticle({ ...newArticle, title: e.target.value })}
            />
            <input
              className='article-control__input'
              type={'text'}
              placeholder='Article Author'
              onChange={(e) => setNewArticle({ ...newArticle, author: e.target.value })}
            />
            <select
              className='article-control__input'
              onChange={(e) => setNewArticle({ ...newArticle, nType: e.target.value })}
            >
              <option value='Announcement'>Announcement</option>
              <option value='News'>News</option>
              <option value='Event'>Event</option>
              <option value='Patch'>Patch</option>
            </select>
          </div>
          <textarea
            className='article-control__textarea'
            placeholder='Article Content as HTML'
            rows={10}
            cols={100}
            onChange={(e) => setNewArticle({ ...newArticle, body: e.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='article-control__btn'>Close</button>
          <button className='article-control__btn' onClick={handleSubmit}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  )
}

export default ArticlePage
